import React from "react";
import cimbanner from "./graphic_assets/cim.svg"
import { Link } from "react-router-dom";
import vissza_gomb from "./graphic_assets/vissza_gomb.svg";


function KerdoIvSegitseg({ setGlobalState, setType, type }) {
    return (
        <div className="welcome-div">
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <img src={cimbanner} alt="Címbanner" style={{ width: "100%", maxWidth: "800px", marginTop: "50px"}}></img>
        <p className="generic">
        Amennyiben kérdése van, vagy segítséget szeretne kérni a fenntarthatósági önértékelő kérdőívvel kapcsolatosan, akkor küldjön egy e-mailt a<br></br>
        <a href="mailto:kerdoiv@agrya.hu">kerdoiv@agrya.hu</a><br></br>
        címre.<br></br><br></br>
        Kollégáink felveszik Önnel a kapcsolatot!</p>
        <Link to="/"><img src={vissza_gomb} alt="Vissza" style={{ width: "90vw", maxHeight: "10vh", marginTop: "25px", marginBottom: "20px"}}></img></Link>
        </div>
        </div>
        )
}

export default KerdoIvSegitseg;